import React                          from 'react';
import { Link }                       from 'react-router-dom';

import consts                         from 'shared/consts';
import * as UserType                  from 'shared/types/User';
import * as OrderType                 from 'shared/types/Order';
import * as LegType                   from 'shared/types/Leg';
import * as WSPayloadType             from 'shared/types/WSPayload';
import * as RouteType                 from 'shared/types/Route';
import * as DurationType              from 'shared/types/Duration';
import arrayDedupe                    from 'shared/utils/arrayDedupe';
import dayjs                          from 'shared/utils/day-timezone';

import Alert                          from 'utils/Alert';
import tectransit                     from 'utils/TecTransit';
import MapOptions                     from 'utils/MapOptions';
import MapVehicle                     from 'utils/MapVehicle';
import getApiPromise                  from 'utils/getApiPromise';
import * as MenuItem                  from 'components/MenuItem';
import RouteContainer                 from 'components/RouteContainer';

const tolerableFRStopArrivalLateSeconds     = 30;
const tolerableFRStopDepartureEarlySeconds  = 30;

class DispatcherMapVehicle extends MapVehicle {
    describeOrder(o:OrderType.Order,event:OrderType.Event) {
        // This method defines what users get to see when they click on a marker on a map
        // * The passenger will see just the orders description
        // * The dispatcher should be able to re-schedule orders
        // The default implementation makes sense for the passenger and - perhaps - driver
        // The dispatchers need to overload this method.
        return `<b>#${o._id||o.scheduler_id}</b> FROM user <b>${o.user ? o.user.name : `#${o.user_id}`} at ${dayjs(event.eta).tz(tectransit.agency.time_zone).format(tectransit.timeFormat)}</b><br>${event.address}`;
    }
    gotLateOrders() : boolean {
        return this.getLegs().some( leg => {
            return [...leg.pickup.orders||[],...leg.dropoff.orders||[]].some(o => {
                return [o.pickup,o.dropoff].some(orderEvent=>(DispatcherMapVehicle.getOrderEventLateness(orderEvent)>=consts.max_vehicle_delay));
            });
        });
    }
    getScale() {
        if( this.serverVehicle.fixed_route_trip ) {
            // Fixed route
            if( (this.frStopArrivalLateSecs||0)>=tolerableFRStopArrivalLateSeconds )
                return 1.5; // vehicle is running cold
            if( (this.frStopDepartureEarlySecs||0)>=tolerableFRStopDepartureEarlySeconds )
                return 1.5; // vehicle if running hot
        }
        else {
            // On demand
            if( this.gotLateOrders() )
                return 1.5;
        }
        return 1
    }
    static getOrderEventLateness( orderEvent:OrderType.Event ) : number {
        return (orderEvent.eta||0)-(orderEvent.earliest_eta||0);
    }
}

export interface Props {
}

export default class Map extends RouteContainer {

    private googleMap?        : google.maps.Map;
    private ws?               : WebSocket;
    private mapBoundaries?    : google.maps.Polyline[];
    private alert             : Alert = new Alert();

    private usersById         : Record<number,UserType.User> = {};
    private mapVehiclesById   : Record<number,DispatcherMapVehicle> = {};

    // @ts-expect-error
    public props : Props;
    public state : {
        currentMs          : number;
        selectedVehicleId? : number;
    } = {
        currentMs          : Date.now()
    };

    // private
    private closeWebSocket( reason:string ) {
        if( this.ws ) {
            try {
                this.ws.close(3001,reason);
            }
            catch (err) {
                console.error(`Cannot close websocket`,err);
            }
            this.ws = undefined;
        }
    }
    private getWebsocket( timeout=1000 ) {
        const onClose = () => {
            timeout *= 1.5; // exponential back-off
            this.ws = undefined;
            window.setTimeout(() => {
                // Re-open only if we haven't explicitly closet the socket when unmounted the component
                if( this.ws )
                    this.ws = this.getWebsocket(timeout);
            },timeout);
        };
        const onData = (data:Record<string,any>) => {
            const dehydrated = data as WSPayloadType.WSPayload.Dehydrated;
            if( WSPayloadType.WSPayload.isValid(dehydrated) ) {
                const wsPayload = WSPayloadType.WSPayload.hydrate(dehydrated);
                this.usersById  = wsPayload.usersById;

                // This will purposefully make the component to re-render
                this.setState({
                    currentMs : (wsPayload.seconds*1000)
                });

                // Put the vehicles on the map
                const timeout        = 9000;
                const serverVehicles = Object.values(wsPayload.vehiclesById);
                const wsPayloadMmnt  = dayjs(wsPayload.seconds*1000).tz(tectransit.agency.time_zone);
                const vehicleInfos   = serverVehicles.reduce( (acc,serverVehicle) => {
                    // Make sure we have DispatcherMapVehicle
                    let mapVehicle = this.mapVehiclesById[serverVehicle._id];
                    if( !mapVehicle ) {
                        mapVehicle = this.mapVehiclesById[serverVehicle._id] = new DispatcherMapVehicle(this.googleMap!);
                        mapVehicle.vehicleMarker.addListener('click',() => {
                            this.setState({
                                // If user already has this vehicle chosen, then unselect it
                                // Otherwise select whatever vehicle the user has chosen
                                selectedVehicleId : ((this.state.selectedVehicleId===serverVehicle._id) ? undefined : serverVehicle._id)
                            });
                        });
                        this.mapVehiclesById[serverVehicle._id] = mapVehicle;
                        console.log(`Added MapVehicle ${serverVehicle._id} to cache`);
                    }
                    if( serverVehicle.fixed_route_trip )
                        mapVehicle.updateFrStop(wsPayloadMmnt);
                    try {
                        // If there is only 1 vehicle then center map and zoom around it
                        if( mapVehicle.update(serverVehicle) && (serverVehicles.length===1) )
                            this.googleMap!.fitBounds(MapOptions.getLegBounds((serverVehicle.route.legs||[])[0]),-0.0625);
                    }
                    catch( err ) {
                        console.error(`Cannot update MapVehicle ${serverVehicle._id}:`,err);
                    }
                    if( Math.abs((serverVehicle.is_online_changed_at||0)-wsPayloadMmnt.valueOf())<timeout )
                        acc.newStatusVehicles.push(serverVehicle);
                    else if( RouteType.getMeters(serverVehicle.route)>tectransit.agency.gps_accuracy_meters ) {
                        if( !serverVehicle.is_online )
                            acc.offlineVehicles.push(serverVehicle);
                        else if( ((Date.now()/1000)-tectransit.agency.max_lateness_seconds)>(serverVehicle.reported_location?.seconds||0) )
                            acc.staleVehicles.push(serverVehicle);
                        else if( !serverVehicle.driverid )
                            acc.nodriverVehicles.push(serverVehicle);
                    }
                    return acc;
                },{
                    newStatusVehicles   : [] as WSPayloadType.Vehicle.Hydrated[],
                    offlineVehicles     : [] as WSPayloadType.Vehicle.Hydrated[],
                    staleVehicles       : [] as WSPayloadType.Vehicle.Hydrated[],
                    nodriverVehicles    : [] as WSPayloadType.Vehicle.Hydrated[],
                });

                // Remove from map the vehicles that no longer known to server (e.g. other agency vehicles)
                Object.values(this.mapVehiclesById).filter(mapVehicle=>!(mapVehicle.serverVehicle._id in wsPayload.vehiclesById)).forEach( mapVehicle => {
                    console.log(`Removing MapVehicle ${mapVehicle.serverVehicle!._id} from cache`);
                    mapVehicle.remove();
                    delete this.mapVehiclesById[mapVehicle.serverVehicle._id];
                    if( !isNaN(this.state.selectedVehicleId!) && (mapVehicle.serverVehicle._id===this.state.selectedVehicleId) ) {
                        this.setState({
                            selectedVehicleId : undefined
                        });
                    }
                });

                // Compose the alert message, if any
                if( !this.alert.message ) {
                    const messages = [
                        { vehicles : vehicleInfos.newStatusVehicles, formatter:(vehicles:WSPayloadType.Vehicle.Hydrated[]) => {
                            return vehicles.map( sv => {
                                return `#${sv._id} went ${sv.is_online?'online':'offline'} at ${dayjs(sv.is_online_changed_at).tz(tectransit.agency.time_zone).format('HH:mm:ss')}`;
                            }).join(';');
                        }},
                        { vehicles : vehicleInfos.offlineVehicles, formatter:(vehicles:WSPayloadType.Vehicle.Hydrated[]) => {
                            return `${vehicles.map(v=>`#${v._id}`)} ${vehicles.length>1?'are':'is'} offline`;
                        }},
                        { vehicles : vehicleInfos.staleVehicles, formatter:(vehicles:WSPayloadType.Vehicle.Hydrated[]) => {
                            return `${vehicles.map(v=>`#${v._id}`)} ${vehicles.length>1?'are':'is'} stale`;
                        }},
                        { vehicles : vehicleInfos.nodriverVehicles, formatter:(vehicles:WSPayloadType.Vehicle.Hydrated[]) => {
                            return `${vehicles.map(v=>`#${v._id}`)} ${vehicles.length>1?'are':'is'} without a driver`;
                        }}
                    ].reduce((acc,vehicleInfo) => {
                        if( vehicleInfo.vehicles.length>0 )
                            acc.push(vehicleInfo.formatter(vehicleInfo.vehicles));
                        return acc;
                    },[] as string[]);
                    if( messages.length>0 )
                        this.alert.set(messages.join(';'),timeout);
                }
            }
            else if( data.message ) {
                console.log(`Got a message from server `,data);
                if( data.donotReopen )
                    this.closeWebSocket(`do not re-open`);
            }
            else {
                console.warn(`Got unrecognized WS payload: `,data);
            }
        };
        return tectransit.getWebsocket('ws/Dispatcher',onClose,onData);
    }
    private getRow( name:React.ReactNode, value?:React.ReactNode ) : JSX.Element {
        if( !value )
            return (
                <div className="driverInfo">
                    <div className="driverInfo__text">{name}</div>
                </div>
            );
        return (
            <div className="driverInfo">
                <div className="driverInfo__title">{name}:</div>
                <div className="driverInfo__text">{value}</div>
            </div>
        );
    }
    // Overloads from RouteContainer
    protected getStepInstructionsNode( leg:LegType.Leg, step:LegType.Step ) : React.ReactNode {
        const serverVehicle = this.mapVehiclesById[this.state?.selectedVehicleId||-1]?.serverVehicle;
        const firstLeg      = serverVehicle?.route.legs[0];
        const firstStep     = firstLeg?.steps[0];
        const getPickupDropoffButtonsTds = ( order:OrderType.Order ) : React.ReactNode => {
            if( !tectransit.user.roles.includes("Dispatcher") ) {
                // This component is also used by "Observer"s. Observers do not have right to change order status
                return false;
            }
            const paddingStyle = {paddingLeft:'1rem'};
            if( (step.maneuver==='pickup') && (order.status==='scheduled') )
                return [
                    (<td key='noshowButton' style={paddingStyle}>
                        <button className="btn-small" onClick={(e)=>this.onNoShow(e,order)}>
                            no show
                        </button>
                    </td>),
                    (<td key='pickupButton' style={paddingStyle}>
                        <button className="btn-small" onClick={(e)=>this.onPickup(e,order)}>
                            {step.maneuver}
                        </button>
                    </td>)
                ];
            if( (step.maneuver==='dropoff') && (order.status==='pickedup') )
                return (<td key='dropoffButton'>
                    <button className="btn-small" onClick={(e)=>this.onDropoff(e,order)}>
                        {step.maneuver}
                    </button>
                </td>)
            return false;
        }
        switch( step.maneuver ) {
        case 'pickup':
        case 'dropoff':
            const legEvent = leg[step.maneuver];
            if( (leg!==firstLeg) || (step!==firstStep) )
                return this.getStepAddress(legEvent,step);
            return (<>
                {this.getStepAddress(legEvent,step)}
                {(legEvent.orders||[]).map( (order,ndx) => {
                    return <table key={ndx}>
                        <tbody>
                            <tr>
                                <td key='name'>(#{order._id}) {step.maneuver} {order.user?.name}&nbsp;</td>
                                {getPickupDropoffButtonsTds(order)}
                            </tr>
                        </tbody>
                    </table>;
                })}
            </>);
        }
        return super.getStepInstructionsNode(leg,step);
    }

    // Action handlers
    protected onUnselectVehicle(e:any) {
        e.preventDefault();
        e.stopPropagation();
        this.setState({
            selectedVehicleId : undefined
        });
    }
    protected onNoShow(e:React.MouseEvent<HTMLButtonElement>, order:OrderType.Order ) {
        e.preventDefault();
        e.stopPropagation();
        if( !window.confirm(`Do you confirm that user '${order.user?.name}' did not show for pickup of order #${order._id}`) )
            return Promise.resolve(false);
        return getApiPromise(`/api/dispatcher/order`,'PUT',{status:'noshow'},{id:order._id})
            .then( res => {
                if( !res || res.err )
                    throw Error(res?.err||`empty response`);
                // If setting of `noshow` is successful then the order will disappear on the next state update
                // It will be obvious, no other confirmation is necessary
            })
            .catch( err => {
                this.alert.set(`Cannot delete order (${err.message})`);
            });
    }
    protected onPickup( e:React.MouseEvent<HTMLButtonElement>, order:OrderType.Order ) {
        e.preventDefault();
        e.stopPropagation();
        return getApiPromise('/api/dispatcher/order','PUT',{status:'pickedup'},{id:order._id})
            .then( res => {
                if( !res || res.err )
                    throw Error(res?.err||`empty response`);
            })
            .catch( err => {
                this.alert.set(`Cannot pickup order (${err.message})`);
            });
    }
    protected onDropoff( e:React.MouseEvent<HTMLButtonElement>, order:OrderType.Order ) {
        e.preventDefault();
        e.stopPropagation();
        return getApiPromise('/api/dispatcher/order','PUT',{status:'finished'},{id:order._id})
            .then( res => {
                if( !res || res.err )
                    throw Error(res?.err||`empty response`);
            })
            .catch( err => {
                this.alert.set(`Cannot dropoff order (${err.message})`);
            });
    }
    // Rendering of different objects
    private getVehicleRows( mapVehicle:DispatcherMapVehicle ) : React.ReactElement[] {
        const serverVehicle = mapVehicle.serverVehicle;
        const getUserLink = ( user?:Partial<UserType.User>, etc?:React.ReactElement ) => {
            if( !user )
                return 'n/a';
            return <Link key={`user_${user?._id}`} to={`/Dispatcher/User/${user?._id}`}>{user?.name||'n/a'}{etc}</Link>;
        };
        const pinColor      = MapVehicle.colors.includes(serverVehicle!.fixed_route_name!) ? serverVehicle!.fixed_route_name! : MapVehicle.colors[serverVehicle!._id%MapVehicle.colors.length];
        const routeSteps    = this.getRouteSteps(serverVehicle,this.state.currentMs/1000);
        const dayTripStats  = serverVehicle.day_trip_stats!;
        const driver        = this.usersById[serverVehicle.reported_driverid!||serverVehicle.driverid!];
        const rows = [
            this.getRow(
                `Vehicle#`,
                <span>
                    {serverVehicle._id??serverVehicle.license_plate}
                    <svg onClick={e=>this.onUnselectVehicle(e)} version="1.1" style={{width:22,height:22}}><g fill={pinColor}><path d={MapVehicle.bus_icon_path}/></g></svg>
                </span>
            ),
            this.getRow("Capacity",((serverVehicle.seats||0)+(serverVehicle.wheelchairs||0))),
            this.getRow("Make, Model, Year",serverVehicle.make_model_year||"n/a"),
            this.getRow("License Plate",serverVehicle.license_plate||"n/a")
        ];
        if( serverVehicle.unavailability_reason ) {
            rows.push(
                this.getRow('Unavailable',<span style={{color:'red'}}>{serverVehicle.unavailability_reason}</span>)
            );
        }
        rows.push(
            this.getRow("Driver",getUserLink(driver,<span title={`Since ${dayjs(serverVehicle.is_online_changed_at).tz(tectransit.agency.time_zone).format(tectransit.timeFormat)}`}>
                {serverVehicle.is_online?'(online)':'(offline)'}
            </span>))
        );
        if( serverVehicle.fixed_route_trip ) {
            const getFaresFromBoardings = ( boardingsByCategory:Record<string,number> ) =>  {
                const faresByCategory = tectransit.agency.fixed_route_fares||{};
                return Object.entries(boardingsByCategory).reduce((acc,[category,count]) => {
                    return {
                        ...acc,
                        [category] : count*(faresByCategory[category]||0)
                    }
                },{} as Record<string,number>);
            }
            const getCountsSummary = ( countsByCategory:Record<string,number>,countFormatter?:((count:number)=>string) ) => {
                if( !countFormatter )
                    countFormatter = (count:number)=>String(count);
                const reduced = Object.entries(countsByCategory).reduce((acc,[category,count])=>{
                    acc.total += count;
                    acc.titles.push(`${category}: ${countFormatter!(count)}`);
                    return acc;
                },{
                    total  : 0,
                    titles : [] as string[]
                });
                return <span title={reduced.titles.join("\n")}>{countFormatter!(reduced.total)}</span>;
            }
            rows.push(
                this.getRow("Trip"          ,serverVehicle.fixed_route_trip?.name||serverVehicle.fixed_route_name),
                this.getRow("Passengers"    ,getCountsSummary(serverVehicle.fixed_route_trip_counts?.passengers||{})),
                this.getRow("Fares"         ,getCountsSummary(getFaresFromBoardings(serverVehicle.fixed_route_trip_counts?.boardings||{}),fare=>`$${fare.toFixed(2)}`))
            );
            if( (mapVehicle.frStopArrivalLateSecs||0)>tolerableFRStopArrivalLateSeconds )
                rows.push(this.getRow(`Arrival Late to current stop`,<span style={{color:'red'}}><strong>{DurationType.secondsToHMS(mapVehicle.frStopArrivalLateSecs)}</strong></span>));
            if( (mapVehicle.frStopDepartureEarlySecs||0)>tolerableFRStopDepartureEarlySeconds )
                rows.push(this.getRow("Depart Early from last stop",<span style={{color:'red'}}><strong>{DurationType.secondsToHMS(mapVehicle.frStopDepartureEarlySecs)}</strong></span>));
        }
        else {
            const routeLegs         = serverVehicle.route.legs||[];
            const pickupOrderIds    = routeLegs.map(l=>l.pickup.orders||[]).flat().map(o=>o._id);
            const carriedOrders     = routeLegs.map(l=>l.dropoff.orders||[]).flat().filter(o=>!pickupOrderIds.includes(o._id));
            const carriedUsers      = arrayDedupe(carriedOrders.map(o=>(o.user as UserType.User)).filter(user=>!!user)).map(user=>getUserLink(user));
            const passengerCount    = carriedOrders.reduce((acc,o)=>acc+(o.seats+o.wheelchairs),0);
            const getLegsOrders = ( legs:LegType.Leg[], event_name:('pickup'|'dropoff') ) => {
                const getOrderLink = ( order:OrderType.Order, inner:React.ReactElement ) : React.ReactElement => {
                    if( event_name==='dropoff' )
                        return (<span key={`${event_name}_order_${order._id}`}>{inner}</span>);
                    return (<Link key={`${event_name}_order_${order._id}`} to="/Dispatcher/Routes">{inner}</Link>);
                };
                return (legs||[]).map(leg => {
                    return (leg[event_name].orders||[]).map( o => {
                        const delta = DispatcherMapVehicle.getOrderEventLateness(o[event_name]);
                        if( delta<consts.max_vehicle_delay )
                            return getOrderLink(o,<>#{o._id||o.scheduler_id}</>);
                        return getOrderLink(o,<>#{o._id||o.scheduler_id} (<span style={{color:"red"}}>{Math.round(delta/1000/60)} min late</span>)</>);
                    });
                });
            };
            rows.push(
                this.getRow("Passengers"        ,passengerCount ? [passengerCount,...carriedUsers] : []),
                this.getRow("Orders to Pickup"  ,getLegsOrders(routeLegs,'pickup')),
                this.getRow("Orders to Dropoff" ,getLegsOrders(routeLegs,'dropoff')),
                this.getRow("Revenue Miles"     ,((dayTripStats.revenue_meters||0)/consts.meters_in_mile).toFixed(2)),
                this.getRow("Non Revenue Miles" ,((dayTripStats.non_revenue_meters||0)/consts.meters_in_mile).toFixed(2))
            )
        }
        rows.push(
            this.getRow(
                (routeSteps.length>0) ? (
                    <table style={{borderSpacing:0}}>
                        <tbody>
                        {routeSteps.map((step,ndx) => {
                            return this.getDirectionsTr([
                                step.maneuverNode,
                                step.instructionsNode,
                                <>{step.getDistanceDuration()}</>
                            ]);
                        })}
                        </tbody>
                    </table>
                ) : (
                    'no directions'
                )
            )
        );
        return rows.map((row,ndx)=>(<React.Fragment key={ndx}>{row}</React.Fragment>));
    }
    private getLateOrders() : React.ReactElement {
        return (
            <div key="lateOrders">
                {Object.values(this.mapVehiclesById).filter(mapVehicle=>mapVehicle.gotLateOrders()).map((mv,ndx)=>{
                    return (<div key={ndx}>Vehicle <b>fleet #{mv.serverVehicle._id}</b> has late orders</div>);
                })}
            </div>
        );
    }
    // public
    componentDidMount() {
        try {
            this.googleMap         = tectransit.getGoogleMap(
                document.getElementById("map")!,
                new MapOptions(MapOptions.getBounds(tectransit.getAgencyBoundary(),-0.0625))
            );
            this.ws                = this.getWebsocket();
            this.mapBoundaries     = (tectransit?.agency?.boundaries||[]).map( (boundary,ndx) => {
                return new google.maps.Polyline({
                    strokeColor   : MapVehicle.colors[ndx%MapVehicle.colors.length],
                    strokeOpacity : 1.0,
                    strokeWeight  : 3,
                    map           : this.googleMap,
                    path          : boundary
                });
            });
            this.mapVehiclesById = {};
        }
        catch( err ) {
            this.alert.set(`Initialization error (${(err as Error).message})`);
        }
    }
    componentWillUnmount() {
        this.closeWebSocket('component unmounts');
        this.mapVehiclesById = {};
    }
    render() {
        return MenuItem.withMenuItem("Map",( alert ) => {
            this.alert = alert;
            const mapVehicle = this.mapVehiclesById[this.state?.selectedVehicleId||-1];
            return (<>
                <div id="map" className="map-card" style={{height:`${window.innerHeight-MenuItem.height}px`}}/>
                <div className="content fixed-content">
                    <div className="wrapper">
                        <div className="section-card">
                            <div id="floatingCard" className="section-card__inner">
                                { this.getRow("Time",dayjs(this.state.currentMs).tz(tectransit.agency.time_zone).format('MM/DD/YYYY HH:mm:ss')) }
                                { mapVehicle ? this.getVehicleRows(mapVehicle) : [this.getLateOrders(),(<div key={Date.now()}>Please click on a vehicle to see details.</div>)] }
                            </div>
                        </div>
                    </div>
                </div>
            </>);
        });
    }
}
